import { Input, message, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';

const { Option } = Select;

const GET_TRANSPORTER = gql`
query {
    transporter @client {
        _id
        name
    }
}
`

const GET_PILOTS = gql`
query GetPilots($tpID: ID!){
    getPilots(tpID: $tpID) {
        _id
        name {
            lastName
            firstName
        }
    }
}
`

const PilotSelect = React.forwardRef((props: SelectProps, ref?: React.LegacyRef<Select>) => {
    const renderSelect = (tpID) => (
        <Query 
            query={GET_PILOTS} 
            variables={{tpID}} 
            onError={() => message.error('Error: Could not load pilots')}
            fetchPolicy="cache-and-network">
            {
                ({ data, loading }) => {
                    const noPilots = !loading && data && data.getPilots && data.getPilots.length <= 0;
                    if (noPilots){
                        return (
                            <Input disabled={true} value="No Pilots Available" />
                        )
                    }
                    return (
                        <Select
                            ref={ref}
                            defaultValue="" 
                            showSearch
                            disabled={noPilots}
                            loading={loading}
                            {...props}
                            optionFilterProp="children"
                            showAction={["focus", "click"]}
                            filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={(!loading && data && data.getPilots && data.getPilots.length && props.value) || ''}>
                            <Option value="">Choose a pilot</Option>
                            {!loading && data && data.getPilots && data.getPilots.map(pilot => (
                                pilot && <Option key={pilot._id} value={pilot._id}>{`${pilot.name.lastName}, ${pilot.name.firstName}`}</Option>
                            ))}
                        </Select>
                    )
                }
            }
        </Query>
    )
    return (
        <Query query={GET_TRANSPORTER} onError={() => message.error("Error: Failed to get current transportation provider")}>
        {
            ({ data, error }: any) => {
                if (data && !error){
                    return renderSelect(data.transporter._id)
                }
                return null
            }
        }
        </Query>
    )
})

PilotSelect.displayName = 'PilotSelect'

export default PilotSelect