import {Cell, Column} from '@blueprintjs/table';
import {Button, Input, List} from 'antd';
import {getFormFieldsValues, getLabelInValueKey} from 'common/form';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import gql from 'graphql-tag';
import DeleteEntity from 'Mutations/DeleteEntity';
import React, {useContext, useRef} from 'react';
import {
    DuplicationChecker,
    MDDetails,
    MDHeader,
    MDLayout,
    MDTable,
    TableLoader,
    useMasterDataState
} from 'components/masterdata-2-0';
import ComplianceRuleEntryForm from './entry-form';
import {
    getMDTableProps,
    MasterDataProps,
    MDStateToDetailsProps,
    MDStateToHeaderProps,
    MDStateToLayoutProps,
    MDStateToTableScrollerProps,
    renderCellFromKeyIndex,
    renderNoData,
    trimString
} from 'components/masterdata-2-0/util';
import {ComplianceRuleFragment} from "../../../../Queries/Compliance";
import {OrgDataContext} from "../../../../context/orgData";
import {
    ComplianceRuleFragmentFragment,
    MangoQueryResult,
    QueryCustomerComplianceRulesArgs,
    CustomerComplianceRulesSearchInput
} from "../../../../__generated__/graphql/types";
import {ComplianceRuleEntryFormData, ComplianceRuleEntryFormDataValues} from "./types";
import {useApolloClient} from "react-apollo";
import shortNameGenerator from "./short-name-generator";


const QUERY_CUSTOMER_COMPLIANCE_RULES = gql`
    query ComplianceRulesPage_ComplianceRulesQuery(
        $customerID: ID!
        $limit: Int
        $skip: Int
        $bookmark: String
    ){
        CustomerComplianceRules(customerID: $customerID, limit: $limit, bookmark: $bookmark, skip: $skip){
            docs {
                ... on ComplianceRule {
                    ...ComplianceRuleFragment
                }
            }
            bookmark
        }
    }
    ${ComplianceRuleFragment}
`

type QueryResult = {
    CustomerComplianceRules: Omit<MangoQueryResult, 'docs'> & { docs: ComplianceRuleFragmentFragment[] }
}

export interface ComplianceRulesPageProps extends MasterDataProps {}

const MUTATION = gql`
    mutation ComplianceRulesPage_SetComplianceRule(
        $_id: ID
        $_rev: String
        $customerID: ID!
        $destinationID: ID
        $jobTypeID: ID
        $type: ComplianceRuleType
        $shortName: String
        $description: String
        $reCompliancePeriod: Int
        $active: Boolean
    ){
        SetComplianceRule(
            _id: $_id
            _rev: $_rev
            customerID: $customerID
            destinationID: $destinationID
            jobTypeID: $jobTypeID
            type: $type
            shortName: $shortName
            description: $description
            reCompliancePeriod: $reCompliancePeriod
            active: $active
        ){
            ...ComplianceRuleFragment
        }
    }
    ${ComplianceRuleFragment}
`

const ComplianceRulesPage: React.FC<ComplianceRulesPageProps> = (props) => {
    const formRef = useRef(null);
    const apolloClient = useApolloClient();
    const orgData = useContext(OrgDataContext);

    const baseQueryArgs: QueryCustomerComplianceRulesArgs = {
        customerID: orgData.customerID
    }

    const MDState = useMasterDataState({
        getQueryData: (data: QueryResult) => data.CustomerComplianceRules.docs,
        getQueryVariables: (searchValues: CustomerComplianceRulesSearchInput) => ({
            ...baseQueryArgs,
            search: {
                ...searchValues
            }
        }),
        queryGQL: QUERY_CUSTOMER_COMPLIANCE_RULES,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        updateQueryAfterFetchMore: (prev: QueryResult, fetchMoreResult: QueryResult) => {
            let result: QueryResult = {
                ...prev,
                CustomerComplianceRules: {
                    ...prev.CustomerComplianceRules,
                    docs: [
                        ...prev.CustomerComplianceRules.docs,
                        ...fetchMoreResult.CustomerComplianceRules.docs
                    ]
                }
            }
            return result;
        },
        transformEditFromExisting: (values) => {
            return {
                ...values
            }
        }
    })
    const data = MDState.data;

    async function handleFieldsChange(_, allFields: ComplianceRuleEntryFormData){

        const allFieldsCpy = {...allFields};

        function createRuleData(data: ComplianceRuleEntryFormDataValues){
            return {
                ...data,
                customerID: orgData.customerID
            } as ComplianceRuleFragmentFragment
        }

        const prevGeneratedShortName = await shortNameGenerator({
            client: apolloClient,
            complianceRuleData: MDState.entryFields?.shortName?.value ? createRuleData(
                getFormFieldsValues(MDState.entryFields) as ComplianceRuleEntryFormDataValues
            ) : {} as ComplianceRuleFragmentFragment
        });

        // eslint-disable-next-line eqeqeq
        const prevShortNameMatchesGen = (allFields.shortName?.value || '') == prevGeneratedShortName;

        const generatedShortName = await shortNameGenerator({
            client: apolloClient,
            complianceRuleData: createRuleData(
                getFormFieldsValues(allFields) as ComplianceRuleEntryFormDataValues
            ) as ComplianceRuleFragmentFragment
        });

        if (prevShortNameMatchesGen){
            // User did not manually change shortName
            allFieldsCpy.shortName = {
                ...allFields.shortName,
                value: generatedShortName
            }
        }

        MDState.setEntryFields(allFieldsCpy);
    }

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                name: MDState.getSearchValue('shortName')
            })
        })}
        headerElement={<MDHeader
            {...MDStateToHeaderProps(MDState)}
            inputElement={<div style={{ display: 'flex', gap: '12px' }}>
                <Input.Search
                    onChange={(e) => MDState.onSearchValueChange('shortName', e.target.value)}
                    placeholder="Search by short name"
                    value={MDState.getSearchValue('shortName')}
                    style={{ width: '15rem' }}
                    allowClear
                />
            </div>}
        />}
        tableElement={<TableLoader mdState={MDState}>
            <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)} >
                <MDTable
                    {...getMDTableProps(data, MDState)}
                    hideDelete
                    columnWidths={[134, 134, 134, 134, 134]}
                >
                    <Column name="Short Name" cellRenderer={renderCellFromKeyIndex(data, 'shortName')} />
                    <Column name="Type" cellRenderer={renderCellFromKeyIndex(data, 'type')} />
                    <Column name="Category" cellRenderer={renderCellFromKeyIndex(data, 'category')} />
                    <Column name="Desc" cellRenderer={renderCellFromKeyIndex(data, 'description')} />
                    <Column name="Active" cellRenderer={(idx) => {
                        let record = data[idx];
                        let active = record && record.active;
                        return <Cell intent={active ? 'success' : 'danger'}>{active ? 'Yes' : 'No'}</Cell>
                    }} />
                </MDTable>
            </BlueprintTableInfiniteScroller>
        </TableLoader>}
        detailsElement={<MDDetails
            {...MDStateToDetailsProps(MDState, 'shortName')}
            onSave={() => {
                formRef.current.validateFieldsAndScroll((err, values: ComplianceRuleEntryFormDataValues) => {
                    if (!err){
                        const data = {
                            ...values,
                            customerID: orgData.customerID
                        }
                        MDState.save(data);
                    }
                })
            }}
        >
            <ComplianceRuleEntryForm
                formFields={MDState.entryFields}
                onFieldsChange={handleFieldsChange}
                uppercaseFields={['shortName']}
                autoFocus
                ref={formRef}
                isNewEntry={MDState.isNewEntry}
                dupeChecker={MDState.isNewEntry ? (
                    <DuplicationChecker
                        skip={
                            !trimString(MDState.getEntryFieldValue('name')) ||
                            !getLabelInValueKey(MDState.getEntryFieldValue('customerID')) ||
                            !getLabelInValueKey(MDState.getEntryFieldValue('locationID'))
                        }
                        getData={data => data && data.MasterDataContracts && data.MasterDataContracts.docs}
                        query={QUERY_CUSTOMER_COMPLIANCE_RULES}

                        // TODO: Implement duplication checker query
                        variables={baseQueryArgs}

                        renderItems={(items) => {
                            return <List size="small">
                                {items.map((rule: ComplianceRuleFragmentFragment, idx) =>
                                    <List.Item key={idx}>
                                        <Button
                                            className="mc-link-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                MDState.editFromExisting(rule, false);
                                            }}
                                        >
                                            <strong>Name: </strong>{rule.shortName}
                                        </Button>
                                    </List.Item>)}
                            </List>
                        }}
                    />
                ) : null}
            >

            </ComplianceRuleEntryForm>
            <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
        </MDDetails>
        }
    />
}

export default ComplianceRulesPage