import gql from "graphql-tag";
import {OrganizationFragment} from "./Organization";
import {LocationFragment} from "./Location";

export const ComplianceRuleFragment = gql`
fragment ComplianceRuleFragment on ComplianceRule {
    _id
    _rev
    modifiedBy
    modifiedTs
    createdTs
    modifiedTs
    classType
    customerID
    customerDoc {
        ...OrganizationFragment
    }
    destinationID
    destinationDoc {
        ...LocationFragment
    }
    jobTypeID
    jobTypeDoc {
        _id
        name
    }
    type
    category
    description
    shortName
    active
}
${OrganizationFragment}
${LocationFragment}
`

export const ComplianceTicketFragment = gql`
    fragment ComplianceTicketFragment on ComplianceTicket {
        _id
        _rev
        modifiedBy
        modifiedTs
        createdTs
        modifiedTs
        classType
        customerID
        customerDoc {
            ...OrganizationFragment
        }
        complianceRuleID
        complianceRuleDoc {
            ...ComplianceRuleFragment
        }
        lastComplianceDate
        nextComplianceDate
    }
    ${ComplianceRuleFragment}
    ${OrganizationFragment}
`

export const ComplianceRuleTypeInfoFragment = gql`
    fragment ComplianceRuleTypeInfoFragment on ComplianceRuleTypeInfo {
        name
        category
        label
    }
`