import gql from "graphql-tag";

export const LiveFlightFragment = gql`
fragment LiveFlightFragment on FlightNode {
    _id,
    desig,
    departureID {
        _id
        name
    }
    aircraftID {
        _id
        maxSeats
    }
    legs
    firstLegUsableWt
    state
    legsArray {
        seats
        paxWeight
        departureID
        destinationID
    }
    paxIDList {
        _id
    }
    cgoIDList {
        _id
    }
}
`