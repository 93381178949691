import {ApolloError} from "apollo-client";
import React, {Dispatch, useContext, useMemo} from "react";
import {produce} from "immer";
import useAutoPaginatedQuery, {PaginatedQueryResult} from "../../../hooks/apollo-extensions/useAutoPaginatedQuery";
import FLIGHTS_QUERY from "./query";
import {fetchMoreAndGetToken} from "../util";
import {GlobalFilterContext} from "../../../context/global-filter";
import {OrgDataContext} from "../../../context/orgData";
import { useExtendedReducer, ThunkAction } from "../../../hooks/custom-reducer-hook";

export const DefaultState = {
    error: null as ApolloError,
    tableSettingsDropdownVisible: false as boolean,
    errorClosed: false as boolean,
    addFlight: {
        visible: false as boolean,
        formData: {} as any
    }
} as const;

export type UiState = typeof DefaultState;

type Action =
    | { type: 'DISMISS_ERROR' }
    | { type: 'SET_ERROR', payload: ApolloError }
    | { type: 'SET_TABLE_SETTINGS_DROPDOWN_VISIBLE', payload: boolean }
    | { type: 'SET_ADD_FLIGHT_VISIBLE', payload: boolean }
    | { type: 'SET_ADD_FLIGHT_FORM_DATA', payload: any }

const Reducer: React.Reducer<UiState, Action> = produce((draft, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            draft.error = action.payload as any;
            draft.errorClosed = false;
            break;
        case 'DISMISS_ERROR':
            draft.errorClosed = true;
            break;
        case 'SET_TABLE_SETTINGS_DROPDOWN_VISIBLE':
            draft.tableSettingsDropdownVisible = action.payload;
            break;
        case 'SET_ADD_FLIGHT_VISIBLE':
            draft.addFlight.visible = action.payload;
            break;
        case 'SET_ADD_FLIGHT_FORM_DATA':
            draft.addFlight.formData = action.payload;
            break;
    }
})

const createApi = (dispatch: Dispatch<Action | ThunkAction<UiState, Action>>) => ({
    error: {
        set: (error: ApolloError) => dispatch({ type: 'SET_ERROR', payload: error }),
        dismiss: () => dispatch({ type: 'DISMISS_ERROR' })
    },
    tableSettingsDropdown: {
        setVisible: (visible: boolean) => dispatch({ type: 'SET_TABLE_SETTINGS_DROPDOWN_VISIBLE', payload: visible })
    },
    addFlight: {
        setVisible: (visible: boolean) => {

            const thunkFunction: ThunkAction<UiState, Action> = (dispatch, getState) => {
                dispatch({ type: 'SET_ADD_FLIGHT_VISIBLE', payload: visible });

                // Clear form data one second after drawer closes
                if (visible === false){
                    setTimeout(() => {
                        const state = getState();
                        if (!state.addFlight.visible){
                            // But only if it's still not visible!
                            dispatch({ type: 'SET_ADD_FLIGHT_FORM_DATA', payload: {} });
                        }
                    }, 1000);
                }
            }

            dispatch(thunkFunction);
        },
        formData: {
            set: (data: any) => dispatch({ type: 'SET_ADD_FLIGHT_FORM_DATA', payload: data })
        }
    }
});

export type Api = ReturnType<typeof createApi>

export type State = {
    uiState: UiState,
    queryResult: PaginatedQueryResult
}

export function useLiveFlightsState(): [State, Api]{
    const globalFilter = useContext(GlobalFilterContext);
    const [ uiState, dispatch ] = useExtendedReducer(Reducer, DefaultState);
    const { customer, transporter } = useContext(OrgDataContext);
    const api = useMemo(() => createApi(dispatch), [dispatch]);

    const queryVars = {
        filter: {
            customers: globalFilter.customer && globalFilter.customer.length ? globalFilter.customer.map(({ key }) => key) : customer && [customer._id],
            transporter: globalFilter.transporter && globalFilter.transporter.length ? globalFilter.transporter.map(({ key }) => key)[0] : transporter && transporter._id,
            departures: globalFilter.departure && globalFilter.departure.map(({ key }) => key),
            onlyShowLive: true
        },
        limit: 100
    }

    const queryResult = useAutoPaginatedQuery(FLIGHTS_QUERY, {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: 20000,
        supressDataUpdates: true,
        onError: (err) => api.error.set(err),
        onPaginationError: (err) => api.error.set(err),
        getNextToken: async (token) => await fetchMoreAndGetToken(queryResult.fetchMore, queryVars, token, 'flights'),
        getTokenFromData: (data) => {
            let token = data?.flights?.bookmark;
            if (token === 'nil'){
                token = null;
            }
            return token;
        }
    })

    const state = {
        uiState: uiState,
        queryResult: queryResult
    }

    return [
        state,
        api
    ]
}