import { FormComponentProps, FormCreateOption } from "antd/lib/form";
import {AsFormData, createFormFields, uppercaseFields} from "common/form";
import { useEffect } from "react";

export interface EntryFormProps<T extends AsFormData<any> = any> extends Partial<FormComponentProps> {
    formFields?: T,
    onFieldsChange?: (fields: Partial<T>, allFields: T) => void,
    onValuesChange?: (changedValues: any, allValues: any) => void,
    uppercaseFields?: Array<string>,
    dupeChecker?: JSX.Element,
    autoFocus?: boolean,
    isNewEntry?:boolean
}

export function entryFormOptions<TOwnProps extends EntryFormProps>(): FormCreateOption<TOwnProps>{
    return {
        mapPropsToFields(props){
            return createFormFields(props.formFields)
        },
        onFieldsChange(props, fields, allFields) {
            return props.onFieldsChange && props.onFieldsChange(uppercaseFields(fields, props.uppercaseFields), uppercaseFields(allFields, props.uppercaseFields));
        },
        onValuesChange(props, changedValues, allValues) {
            return props.onValuesChange && props.onValuesChange(uppercaseFields(changedValues, props.uppercaseFields), uppercaseFields(allValues, props.uppercaseFields));
        }
    }
}

// TODO: This function is problematic b/c it tends to not work at all and/or steals focus away while a user is typing in another field.
// I have disabled it for now until we can either get it working properly or remove it entirely. - Austin
export function useAutoFocus(autoFocusProp: boolean, inputElement: HTMLInputElement){
    return;

    /*
    useEffect(() => {
        console.debug('autoFocusProp: ' + autoFocusProp + ' inputElement: ', inputElement)
        if (autoFocusProp && inputElement){
            console.debug('autofocusing element', inputElement);
            inputElement.focus();
        }
    }, [ autoFocusProp, inputElement ])
    */
}