import {Dispatch, Reducer, ReducerAction, ReducerState, useReducer, useRef} from 'react';
import {Middleware, ThunkAction} from "./types";

/**
 * Custom hook that enhances `useReducer` by integrating middleware and thunk functionality.
 *
 * @template S - State type.
 * @template A - Action type.
 * @param {Reducer<S, A>} reducer - Reducer function to process actions.
 * @param {S} initialState - The initial state value.
 * @param {Middleware<S, A>[]} middlewares - Middleware functions to intercept actions.
 * @param initializer {I => S} - Initializer function
 * @returns {[S, Dispatch<A | ThunkAction<S, A>>]} - An array containing the current state and a dispatch function.
 *
 * @example
 * // Define state and action types
 * type State = { count: number };
 * type Action = { type: 'increment' } | { type: 'decrement' };
 *
 * // Reducer function
 * const reducer: Reducer<State, Action> = (state, action) => {
 *   switch (action.type) {
 *     case 'increment':
 *       return { count: state.count + 1 };
 *     case 'decrement':
 *       return { count: state.count - 1 };
 *     default:
 *       return state;
 *   }
 * };
 *
 * // Middleware to log actions
 * const loggerMiddleware: Middleware<State, Action> = (state, action) => {
 *   console.log('Dispatching:', action);
 * };
 *
 * // Using the hook in a component
 * const [state, dispatch] = useExtendedReducer(reducer, { count: 0 }, loggerMiddleware);
 *
 * // Dispatching a thunk action
 * dispatch((dispatch, getState) => {
 *   if (getState().count < 5) {
 *     dispatch({ type: 'increment' });
 *   }
 * });
 */
export function useExtendedReducer<S, I, A>(
    reducer: Reducer<S, A>,
    initialState: I & S,
    middlewares?: Middleware<S, A>[],
    initializer?: (init: I & S) => S
): [S, Dispatch<A | ThunkAction<S, A>>] {
    const [state, originalDispatch] = useReducer(reducer, initialState, initializer);
    const stateRef = useRef(state);

    // Update stateRef whenever state changes
    stateRef.current = state;

    // Function to get the latest state
    const getState = () => stateRef.current;

    // Custom dispatch function
    const dispatch: Dispatch<any> = (action: A | ThunkAction<S, A>) => {
        if (typeof action === 'function') {
            // Handle thunk actions
            (action as ThunkAction<S, A>)(dispatch, getState);
        } else {
            // Apply middleware to actions
            const applyMiddleware = (index: number) => {
                if (Array.isArray(middlewares) && index < middlewares.length) {
                    middlewares[index](stateRef.current, action, dispatch, getState);
                    applyMiddleware(index + 1);
                } else {
                    // After all middleware have run, dispatch the action
                    originalDispatch(action);
                }
            };
            applyMiddleware(0);
        }
    };

    return [state, dispatch];
}


export default useExtendedReducer;