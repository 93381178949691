import {
    ComplianceRuleFragmentFragment,
    ComplianceRuleType,
    OrganizationFragmentFragment
} from "../../../../__generated__/graphql/types";
import ApolloClient from "apollo-client";
import {OrganizationFragment} from "../../../../Queries/Organization";
import gql from "graphql-tag";
import {isTruey} from "../../../../common/util-ts";

const GET_CUSTOMER_QUERY = gql`
query ShortNameGenerator_GetCustomer($id: ID!){
    getCustomer(_id: $id){
        ...OrganizationFragment
    }
}
${OrganizationFragment}
`

interface ShortNameGeneratorArgs {
    client: ApolloClient<object>,
    complianceRuleData: ComplianceRuleFragmentFragment
}

type GenCallback = (args: ShortNameGeneratorArgs) => Promise<string>

const genCustomerName: GenCallback = async ({ complianceRuleData, client }) => {
    if (complianceRuleData.customerID){
        let org = client.readFragment<OrganizationFragmentFragment>({
            id: 'Organization:' + complianceRuleData.customerID,
            fragmentName: 'OrganizationFragment',
            fragment: OrganizationFragment
        });

        if (!org){
            const res = await client.query({
                query: GET_CUSTOMER_QUERY,
                variables: {
                    id: complianceRuleData.customerID
                },
                fetchPolicy: 'cache-first'
            });

            if (res.data?.getCustomer){
                org = res.data.getCustomer;
            }
        }

        if (org){
            return org.name;
        }
    }

    return '';
}

const genTypeName: GenCallback = async ({ complianceRuleData }) => {
    if (complianceRuleData.type){
        let finalStr = complianceRuleData.type as string;

        if (complianceRuleData.type === ComplianceRuleType.DRUG_AND_ALCOHOL){
            finalStr = 'DA';
        }

        return finalStr;
    }

    return '';
}

async function shortNameGenerator(args: ShortNameGeneratorArgs){

    const pipeline: GenCallback[] = [
        genTypeName,
        genCustomerName
    ];

    const nameParts = (await Promise
        .all(pipeline.map((cb) => cb(args))))
        .filter(isTruey);

    return nameParts.join('-');
}

export default shortNameGenerator;