import {Checkbox, Form, Icon, Input, InputNumber, Select, Typography} from 'antd';
import {FormComponentProps} from 'antd/lib/form';
import React, {forwardRef, LegacyRef, useRef} from 'react';
import {entryFormOptions, EntryFormProps, useAutoFocus} from 'components/masterdata-2-0/entryForms';
import {ComplianceRuleEntryFormData, ComplianceRuleEntryFormDataValues} from "./types";
import {SelectProps} from "antd/lib/select";
import {ComplianceRuleType} from "../../../../__generated__/graphql/types";
import ComplianceRuleTypeInfo from "components/compliance/compliance-rule-type-info";

export interface ComplianceRuleEntryFormProps extends EntryFormProps<ComplianceRuleEntryFormData> {

}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
}

const typeHumanMap: Partial<Record<keyof typeof ComplianceRuleType, string>> = {
    DRUG_AND_ALCOHOL: 'Drug And Alcohol Test',
    FIRE_PROTECTION_EXTINGUISHERS_AWARENESS: 'Fire Protection Awareness Training',
    JOB_SPECIFIC_SKILLS_AND_KNOWLEDGE_VERIFICATION: 'Job Specific Skills and Knowledge Verification',
    MARINE_TRASH_AND_DEBRIS: 'Marine Trash and Debris',
    OFFSHORE_SAFETY_AWARENESS_TRAINING: 'Offshore Safety Awareness Training',
    SEMS_AWARENESS_VIDEO: 'SEMS Awareness Video',
    WATER_SURVIVAL: 'Water Survival (HUET or equivalent)'
}

const ComplianceRuleTypeSelect: React.FC<SelectProps & { title: string }> = ({ title, ...props }) => {

    const data = Array.from(Object.keys(ComplianceRuleType))
            .filter((key) => key !== ComplianceRuleType.NONE);

    const options = data.map((type) => (
        <Select.Option key={type} value={type} style={{ whiteSpace: 'break-spaces' }}>{typeHumanMap[type] || type}</Select.Option>
    ))

    const category = <ComplianceRuleTypeInfo
        type={props.value as ComplianceRuleType}
        render={(_, { current, loadingIndicator }) => {

            const cat = current ?? 'UNKNOWN';

            return (
                <span>
                    <span style={{marginRight: '6px'}}>
                        <Typography.Text>
                            Category:
                        </Typography.Text>
                    </span>
                    {loadingIndicator ? loadingIndicator : cat}
                </span>
            )
        }}
        renderMode="category"
    />

    return <div title={title}>
        <Select {...props}>
            {options}
        </Select>
        {category}
    </div>
}

const ComplianceRuleEntryForm: React.FC<ComplianceRuleEntryFormProps> = forwardRef(
function ComplianceRuleEntryForm(props, ref: LegacyRef<Form>){
    const nameInputRef = useRef(null);
    const { getFieldDecorator, getFieldError, getFieldValue } = props.form;

    useAutoFocus(props.autoFocus, nameInputRef.current);

    let newEntryDisabledMsg = `This data cannot be changed on existing compliance rules. 
    You must create a new compliance rule first.`;

    if (!props.isNewEntry){
        newEntryDisabledMsg = undefined;
    }

    const shortNameHelpMsg = !getFieldError('shortName') && !getFieldValue('shortName') ?
        "Leave this blank if you want a name generated for you" : undefined

    return <Form ref={ref}>
        <Form.Item
            label="Short Name"
            help={shortNameHelpMsg}
            {...formItemLayout}
        >
            {getFieldDecorator('shortName', {
                rules: [
                    { required: true, message: 'Short Name is required' }
                ]
            })(<Input ref={nameInputRef} disabled={!props.isNewEntry} title={newEntryDisabledMsg} />)}
        </Form.Item>
        <Form.Item label="Type" {...formItemLayout}>
            {getFieldDecorator('type', {
                rules: [
                    { required: true, message: 'Type is required' }
                ]
            })(<ComplianceRuleTypeSelect disabled={!props.isNewEntry} title={newEntryDisabledMsg} />)}
        </Form.Item>
        <Form.Item label="Description" {...formItemLayout}>
            {getFieldDecorator('description')(<Input.TextArea />)}
        </Form.Item>
        <Form.Item label="Active" {...formItemLayout}>
            {getFieldDecorator('active', {
                initialValue: true,
                valuePropName: 'checked'
            })(<Checkbox />)}
        </Form.Item>
        {props.dupeChecker}
    </Form>
})

export default Form.create<ComplianceRuleEntryFormProps & FormComponentProps<ComplianceRuleEntryFormDataValues>>(
    entryFormOptions<ComplianceRuleEntryFormProps>()
)(ComplianceRuleEntryForm)