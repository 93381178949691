import React from 'react';
import {Layout, Breadcrumb, Select, Dropdown, Menu} from 'antd';
import { basePath } from '../../flights/scheduling/main';
import { Link, withRouter } from 'react-router-dom';
import { withUserGroups } from '../../userGroup';
import './SchedulingStepsHeader.less';
import {isTruey} from "../../../common/util-ts";
import {graphql} from "react-apollo";
import {gql} from "apollo-boost";
import compose from 'lodash.flowright';
import {Phase1StepQuery} from "../../../Queries/Scheduling";
import withOrgData from "../../WithOrgData";

const { Header } = Layout;

const breadcrumbItems = [
    {
        select: [
            {
                text: 'Passenger Sets',
                to: '/app/scheduling/sets',
                userGroup: ['flytsuite.schedule.sets'],
                requireV1Scheduler: true,
                isSelected: (props) => {
                    return props.data.getPhase1Step === 'sets';
                }
            },
            {
                text: 'History',
                to: '/app/scheduling/history',
                requireV1Scheduler: false,
                isSelected: (props) => {
                    return props.data.getPhase1Step === 'history';
                }
            }
        ]
    },
    {
        text: 'Scheduled Passengers and Cargo',
        to: '/app/scheduling/groups',
        userGroup: ['flytsuite.schedule.passenger', 'flytsuite.schedule.passenger.view']
    },
    {
        text: 'Scheduled Flights',
        to: '/app/scheduling/flight',
        userGroup: ['flytsuite.schedule.flight']
    }
]

class SchedulingStepsHeader_Internal extends React.Component{

    constructor(props) {
        super(props);

        this.unlisten = React.createRef(null);
    }

    getPathName = () => {
        let pathname = this.props.location.pathname;
        if (pathname.startsWith('/app/scheduling/schedule')){
            // Keep highlight when on V2 scheduling page
            pathname = pathname.replace('/app/scheduling/schedule', '/app/scheduling/groups')
        }

        return pathname;
    }

    onRouteChanged = (location) => {
        if (location.pathname === '/app/scheduling/history'){
            this.props.setPhase1Step({
                variables: {
                    step: 'history'
                }
            })
        }

        if (location.pathname === '/app/scheduling/sets'){
            this.props.setPhase1Step({
                variables: {
                    step: 'sets'
                }
            })
        }
    }

    componentDidMount() {
        this.unlisten.current = this.props.history.listen(this.onRouteChanged);
    }

    componentWillUnmount() {
        this.unlisten.current();
    }

    // componentDidUpdate() {
    //
    //     const routeHistory = this.props.history;
    //     const currentLocation = routeHistory[-1];
    //     const lastLocation = routeHistory[-2];
    //     const currPathName = currentLocation?.pathname;
    //     const lastPathName = lastLocation?.pathname;
    //
    //     if (lastPathName !== currPathName){
    //         if (this.props.location.pathname === '/app/scheduling/history'){
    //             this.props.setPhase1Step({
    //                 variables: {
    //                     step: 'history'
    //                 }
    //             })
    //         }
    //
    //         if (this.props.location.pathname === '/app/scheduling/sets'){
    //             this.props.setPhase1Step({
    //                 variables: {
    //                     step: 'sets'
    //                 }
    //             })
    //         }
    //
    //         this.setState({ lastPathName: this.props.location.pathname })
    //     }
    // }

    renderBreadcrumbs = () => (
        breadcrumbItems.map((item, index) => {
            let pathname = this.getPathName();

            const renderText = (config) => {
                var style = {}

                if (pathname.startsWith(config.to)){
                    style = {
                        color: '#1890ff'
                    }
                }

                return <span style={style}>{config.text}</span>
            }

            const renderLink = (config) => {
                const text = renderText(config);

                if (text) return <Link to={config.to}>{text}</Link>
                return <span></span>;
            }

            /**
             * @type {React.ReactNode}
             */
            let link;

            if (item.select){

                const configs = item.select
                    .filter((config) => (
                        config.requireV1Scheduler ? (
                            this.props.orgData.customer?.useV1Scheduler
                        ) : true
                    ))

                if (configs.length === 1){
                    link = renderLink(configs[0]);
                }
                else {
                    const menu = <Menu>
                        {configs.map((config) => (
                            <Menu.Item key={config.to}>
                                {renderLink(config)}
                            </Menu.Item>
                        ))}
                    </Menu>

                    const selected = configs.find(
                        (config) => config.isSelected ?
                            config.isSelected(this.props) :
                            pathname.startsWith(config.to)
                    ) || configs[0];

                    link = (
                        <Dropdown overlay={menu}>
                            {renderLink(selected)}
                        </Dropdown>
                    )
                }
            }
            else
            {
                link = renderLink(item);
            }

            return (
                <Breadcrumb.Item key={index}>
                    {link}
                </Breadcrumb.Item>
            )
        })
    )
    render(){
        return (
        <Header className="scheduling-steps-header">
            <Breadcrumb style={{fontSize: '1rem'}} separator=">">
                <Breadcrumb.Item>
                    <Link to={basePath()}>{/*<Icon type="home" style={{fontSize: '1rem'}} />*/}Start</Link>
                </Breadcrumb.Item>
                {this.renderBreadcrumbs()}
            </Breadcrumb>
        </Header>
        )
    }
}

const SET_PHASE1_STEP = gql`
mutation SchedulingStepsHeaderSetPhase1Step($step: Phase1Step) {
    setPhase1Step(step: $step) @client
}
`

const SchedulingStepsHeader = compose(
    graphql(Phase1StepQuery, { options: { fetchPolicy: 'network-only' } }),
    graphql(SET_PHASE1_STEP, { name: 'setPhase1Step' })
)(withRouter( withUserGroups( withOrgData( SchedulingStepsHeader_Internal ) )))

SchedulingStepsHeader.displayName = 'SchedulingStepsHeader';

export default SchedulingStepsHeader