import gql from "graphql-tag";
import {
    ComplianceRuleType,
    ComplianceRuleTypeInfo,
    QueryGetComplianceRuleTypeInfoArgs, QueryGetComplianceRuleTypeInfosArgs
} from "__generated__/graphql/types";
import {useQuery} from "react-apollo";
import {ComplianceRuleTypeInfoFragment} from "Queries/Compliance";

const QUERY_INFO = gql`
    query UseComplianceRuleTypeInfoQuery($type: ComplianceRuleType!) {
        GetComplianceRuleTypeInfo(type: $type){
            ...ComplianceRuleTypeInfoFragment
        }
    }
    ${ComplianceRuleTypeInfoFragment}
`

const QUERY_INFOS = gql`
    query UseComplianceRuleTypeInfosQuery($types: [ComplianceRuleType!]!) {
        GetComplianceRuleTypeInfos(types: $types){
            ...ComplianceRuleTypeInfoFragment
        }
    }
    ${ComplianceRuleTypeInfoFragment}
`

type QueryResultInfo = { GetComplianceRuleTypeInfo: ComplianceRuleTypeInfo }
type QueryResultInfos = { GetComplianceRuleTypeInfos: ComplianceRuleTypeInfo[] }

export function useComplianceRuleTypeInfoQuery(type: ComplianceRuleType){

    const { data, ...result } = useQuery<QueryResultInfo, QueryGetComplianceRuleTypeInfoArgs>(
        QUERY_INFO,
        {
            variables: {
                type: type
            },
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
            skip: !type
        }
    )

    return {
        ...result,
        data: data?.GetComplianceRuleTypeInfo
    };
}

export function useComplianceRuleTypeInfosQuery(types: ComplianceRuleType[]){

    const { data, ...result } = useQuery<QueryResultInfos, QueryGetComplianceRuleTypeInfosArgs>(
        QUERY_INFOS,
        {
            variables: {
                types: types
            },
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
            skip: !types
        }
    )

    return {
        ...result,
        data: data?.GetComplianceRuleTypeInfos
    };
}