import {ComplianceRuleType, ComplianceRuleTypeInfo} from "__generated__/graphql/types";
import { useComplianceRuleTypeInfoQuery } from "../graphql";
import {Icon, Skeleton, Spin, Typography} from "antd";
import React from "react";

const textRenderers = {
    'category': (data: ComplianceRuleTypeInfo) => data?.category,
    'name': (data: ComplianceRuleTypeInfo) => data?.name,
    'label': (data: ComplianceRuleTypeInfo) => data?.label
}

export type RenderMode = keyof typeof textRenderers;

interface BaseProps {
    render?: (
        data: ComplianceRuleTypeInfo,
        options: {
            loadingIndicator: React.ReactElement<Spin>,
            current: React.ReactElement
        }
    ) => React.ReactElement,
    renderText?: (data: ComplianceRuleTypeInfo, current: string) => string,
    renderMode?: RenderMode,
    loadingElement?: React.ReactElement
}

type WithType = BaseProps & { type: ComplianceRuleType }
type WithData = BaseProps & { data: ComplianceRuleTypeInfo }

export type ComplianceRuleTypeInfoProps = WithType | WithData

function renderLoading(props: ComplianceRuleTypeInfoProps){
    if (props.loadingElement){
        return props.loadingElement
    }
    return <Spin indicator={<Icon type="loading" />} />
}

function renderText(data: ComplianceRuleTypeInfo, props: ComplianceRuleTypeInfoProps){

    if (!data) return null;

    let text = textRenderers[props.renderMode]?.(data);
    if (typeof props.renderText === 'function'){
        text = props.renderText(data, text);
    }

    return <Typography.Text>{text}</Typography.Text>;
}

const ComplianceRuleTypeInfoDisplay: React.FC<ComplianceRuleTypeInfoProps> = (props) => {
    const type = 'type' in props ? props.type : undefined;
    const { data: _data, loading } = useComplianceRuleTypeInfoQuery(type);

    const data = 'data' in props ? props.data : _data;

    let text = renderText(data, props);

    if (typeof props.render === 'function'){
        return props.render(data, {
            loadingIndicator: loading ? renderLoading(props) : null,
            current: text
        });
    }

    if (loading){
        return renderLoading(props)
    }

    return text
}

ComplianceRuleTypeInfoDisplay.defaultProps = {
    renderMode: 'label'
}

export default ComplianceRuleTypeInfoDisplay;