import {Api, UiState} from "./state";
import {Alert, Button, Col, DatePicker, Drawer, Form, Input, Row, Tooltip} from "antd";
import gql from "graphql-tag";
import {FormComponentProps} from "antd/lib/form";
import React, {useContext} from "react";
import {useMutation} from "react-apollo";
import moment from "moment";
import {LocationSelect} from "../../form/select/LocationSelect";
import AircraftSelect from "../../form/AircraftSelect";
import ContractSelect from "../../form/ContractSelect";
import PilotSelect from "../../form/PilotSelect";
import {OrgDataContext} from "../../../context/orgData";
import {produce} from "immer";
import {LiveFlightFragment} from "../../../Queries/LiveFlights";
import FlightLegManager from "../../../common/flight-leg/flight-leg-manager";
import {FlightNodeInputState} from "../../../__generated__/graphql/types";
import {Link} from "react-router-dom";

export type AddFlightDrawerProps = FormComponentProps & {
    uiState: UiState,
    api: Api,
    onSubmitted: (flightId: string) => void
}

const defaultFormOptions = (initialValue?: any, required = true) => ({
    rules: [
        {
            required,
            message: 'This field is required'
        }
    ],
    initialValue
})

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const CREATE_FLIGHT = gql`
    mutation Live_AddFlightsDrawerMutation($payload: FlightNodeInput!, $orgID: ID!){
        setFlight(
            payload: $payload,
            orgID: $orgID
        ){
            ...LiveFlightFragment
        }
    }
    ${LiveFlightFragment}
`

const AddFlightDrawer: React.FC<AddFlightDrawerProps> = (
    {   uiState,
        api,
        form,
        onSubmitted
    }
) => {

    const orgData = useContext(OrgDataContext);

    const [ createFlight, { loading, error } ] = useMutation(CREATE_FLIGHT, {
        onCompleted: (data) => {
            if (typeof onSubmitted === 'function' && data?.setFlight){
                const flight = data.setFlight;
                onSubmitted(flight._id);
            }
        }
    });

    function submit(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {

            const flightInput = {
                ...values,
                contractID: values.contractID,
                customerID: orgData.getOrgIDByType('customer'),
                tpID: orgData.getOrgIDByType('transporter'),
                lastKnownController: values.lastKnownController,
                departureID: values.departureID.key,
                scheduledFlightDate: moment().format('YYYY-MM-DD'),
                state: FlightNodeInputState.ACTIVE
            };

            const locationIdToNameMap = new Map<string, string>();
            if (values.departureID.key){
                locationIdToNameMap.set(values.departureID.key, values.departureID.label);
            }

            const legManager = new FlightLegManager({
                locationIDToNameMap: locationIdToNameMap,
                overrideOrigin: values.departureID.key,
                flightPathOptionalArgs: {
                    preventDupeNodeInsertion: false
                }
            });

            legManager.flightPath.addNextNode(values.departureID.key, true);

            const legs = legManager.buildFlightLegs();

            flightInput.legs = JSON.stringify(Object.assign({}, legs));

            if (!err) {
                createFlight({
                    variables: {
                        payload: flightInput,
                        orgID: orgData.getActiveOrgID()
                    }
                })
            }
        });
    }
    function renderContent(){
        const { getFieldDecorator, getFieldValue } = form;
        return (
            <Form onSubmit={submit}>
                {error && <Alert type="error" message="Failed to create flight" description={error.message} showIcon />}
                <Form.Item {...formItemLayout} label="Scheduled Flight Date">
                    {getFieldDecorator('scheduledFlightDate', defaultFormOptions(moment()))(
                        <Tooltip
                            title={
                            <>
                                <span>{"Live flights can only be created for the current date. " +
                                "If you want to schedule this flight for a future date, " +
                                "please use the "}</span>
                                <Link to="/app/scheduling">scheduling feature.</Link>
                            </>
                            }
                                >
                            <DatePicker
                                disabled
                                format="YYYY-MM-DD"
                                value={moment()}
                            />
                        </Tooltip>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Flight Designation">
                    {getFieldDecorator('desig', defaultFormOptions())(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Controlling Heliport">
                    {getFieldDecorator('lastKnownController', defaultFormOptions())(
                        <LocationSelect
                            type='ONSHORE'
                            includeOneOff
                            hasDataflytHardware
                            limit={50}
                        />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Departure">
                    {getFieldDecorator('departureID', defaultFormOptions())(
                        <LocationSelect labelInValue />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Aircraft">
                    {getFieldDecorator('aircraftID', defaultFormOptions())(
                        <AircraftSelect />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Contract">
                    {getFieldDecorator('contractID', defaultFormOptions())(
                        <ContractSelect
                            orgType="customer"
                            placeholder="Choose a contract"
                        />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Pilot">
                    {getFieldDecorator('pilotID', defaultFormOptions('', false))(
                        <PilotSelect />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Co-Pilot">
                    {getFieldDecorator('copilotID', defaultFormOptions('', false))(
                        <PilotSelect />
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Row type="flex" gutter={6}>
                        <Col>
                            <Button htmlType="submit" loading={loading} type="primary">Create Flight</Button>
                        </Col>
                        <Col>
                            <Button onClick={() => form.resetFields()}>Reset</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        )
    }

    return <Drawer
        title="Add flight"
        visible={uiState.addFlight.visible}
        onClose={() => api.addFlight.setVisible(false)}
        width="35rem"
        closable
    >
        {renderContent()}
    </Drawer>
}

export default Form.create<AddFlightDrawerProps>({
    mapPropsToFields(props){
        return props.uiState.addFlight.formData
    },
    onFieldsChange:(props, changedFields, allFields) => {
        const data = produce(allFields, (draft: any) => {
            if(
                'lastKnownController' in changedFields &&
                changedFields.lastKnownController?.value !== props.form.getFieldValue('lastKnownController')){
                draft.contractID.value = null;
            }
        });

        props.api.addFlight.formData.set(data);
    }})(AddFlightDrawer)